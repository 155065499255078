.global-source[data-v-a4b1beb8] {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer;
}
.margin_r_18[data-v-a4b1beb8] {
  margin-right: 18px;
}
.flex_center_[data-v-a4b1beb8] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.flex_center_center[data-v-a4b1beb8] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.global-source[data-v-a4b1beb8] {
  opacity: 0;
  position: fixed;
  z-index: 9999;
  right: -11.5px;
  bottom: 20px;
  font-size: 12px;
  line-height: 12px;
  font-weight: bold;
  border-radius: 4px;
  padding: 7px 15px;
  padding-right: 19px;
  background-color: rgba(0, 0, 0, 0.7);
  border: 1px solid #000;
  color: #fff;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.global-source.global-source--active[data-v-a4b1beb8] {
  opacity: 1;
}
.global-source[data-v-a4b1beb8]:hover {
  right: -4px;
  background-color: rgba(0, 0, 0, 0.9);
}