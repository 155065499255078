@charset "UTF-8";
html #app, body #app {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.margin_r_18 {
  margin-right: 18px;
}

.flex_center_ {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.flex_center_center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

html #app, body #app {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.margin_r_18 {
  margin-right: 18px;
}

.flex_center_ {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.flex_center_center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

html, body {
  margin: 0px;
  height: 100%;
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
}
html #app a, body #app a {
  text-decoration: none;
}

.el-card.is-always-shadow {
  -webkit-box-shadow: 0 0 8px 0 rgba(232, 237, 250, 0.6), 0 2px 4px 0 rgba(232, 237, 250, 0.5);
          box-shadow: 0 0 8px 0 rgba(232, 237, 250, 0.6), 0 2px 4px 0 rgba(232, 237, 250, 0.5);
}
.el-card.is-hover-shadow:hover {
  -webkit-box-shadow: 0 0 8px 0 rgba(232, 237, 250, 0.6), 0 2px 4px 0 rgba(232, 237, 250, 0.5);
          box-shadow: 0 0 8px 0 rgba(232, 237, 250, 0.6), 0 2px 4px 0 rgba(232, 237, 250, 0.5);
}

.el-menu--horizontal {
  border-bottom: none !important;
}

.el-tabs__item:focus.is-active.is-focus:not(:active) {
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}

.fade-transverse-leave-active,
.fade-transverse-enter-active {
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.fade-transverse-enter {
  opacity: 0;
  -webkit-transform: translateX(-30px);
          transform: translateX(-30px);
}

.fade-transverse-leave-to {
  opacity: 0;
  -webkit-transform: translateX(30px);
          transform: translateX(30px);
}

.fade-scale-leave-active,
.fade-scale-enter-active {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.fade-scale-enter {
  opacity: 0;
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

.fade-scale-leave-to {
  opacity: 0;
  -webkit-transform: scale(0.8);
          transform: scale(0.8);
}

.global-text-center {
  text-align: center;
}

.global-fl {
  float: left;
}

.global-fr {
  float: right;
}

.global-m-0 {
  margin: 0px !important;
}

.global-mt-0 {
  margin-top: 0px !important;
}

.global-mr-0 {
  margin-right: 0px !important;
}

.global-mb-0 {
  margin-bottom: 0px !important;
}

.global-ml-0 {
  margin-left: 0px !important;
}

.global-p-0 {
  padding: 0px !important;
}

.global-pt-0 {
  padding-top: 0px !important;
}

.global-pr-0 {
  padding-right: 0px !important;
}

.global-pb-0 {
  padding-bottom: 0px !important;
}

.global-pl-0 {
  padding-left: 0px !important;
}

.global-m-5 {
  margin: 5px !important;
}

.global-mt-5 {
  margin-top: 5px !important;
}

.global-mr-5 {
  margin-right: 5px !important;
}

.global-mb-5 {
  margin-bottom: 5px !important;
}

.global-ml-5 {
  margin-left: 5px !important;
}

.global-p-5 {
  padding: 5px !important;
}

.global-pt-5 {
  padding-top: 5px !important;
}

.global-pr-5 {
  padding-right: 5px !important;
}

.global-pb-5 {
  padding-bottom: 5px !important;
}

.global-pl-5 {
  padding-left: 5px !important;
}

.global-m-10 {
  margin: 10px !important;
}

.global-mt-10 {
  margin-top: 10px !important;
}

.global-mr-10 {
  margin-right: 10px !important;
}

.global-mb-10 {
  margin-bottom: 10px !important;
}

.global-ml-10 {
  margin-left: 10px !important;
}

.global-p-10 {
  padding: 10px !important;
}

.global-pt-10 {
  padding-top: 10px !important;
}

.global-pr-10 {
  padding-right: 10px !important;
}

.global-pb-10 {
  padding-bottom: 10px !important;
}

.global-pl-10 {
  padding-left: 10px !important;
}

.global-m-15 {
  margin: 15px !important;
}

.global-mt-15 {
  margin-top: 15px !important;
}

.global-mr-15 {
  margin-right: 15px !important;
}

.global-mb-15 {
  margin-bottom: 15px !important;
}

.global-ml-15 {
  margin-left: 15px !important;
}

.global-p-15 {
  padding: 15px !important;
}

.global-pt-15 {
  padding-top: 15px !important;
}

.global-pr-15 {
  padding-right: 15px !important;
}

.global-pb-15 {
  padding-bottom: 15px !important;
}

.global-pl-15 {
  padding-left: 15px !important;
}

.global-m-20 {
  margin: 20px !important;
}

.global-mt-20 {
  margin-top: 20px !important;
}

.global-mr-20 {
  margin-right: 20px !important;
}

.global-mb-20 {
  margin-bottom: 20px !important;
}

.global-ml-20 {
  margin-left: 20px !important;
}

.global-p-20 {
  padding: 20px !important;
}

.global-pt-20 {
  padding-top: 20px !important;
}

.global-pr-20 {
  padding-right: 20px !important;
}

.global-pb-20 {
  padding-bottom: 20px !important;
}

.global-pl-20 {
  padding-left: 20px !important;
}

.global-m {
  margin: 20px !important;
}

.global-mt {
  margin-top: 20px !important;
}

.global-mr {
  margin-right: 20px !important;
}

.global-mb {
  margin-bottom: 20px !important;
}

.global-ml {
  margin-left: 20px !important;
}

.global-p {
  padding: 20px !important;
}

.global-pt {
  padding-top: 20px !important;
}

.global-pr {
  padding-right: 20px !important;
}

.global-pb {
  padding-bottom: 20px !important;
}

.global-pl {
  padding-left: 20px !important;
}

/*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/*定义滑块 内阴影+圆角*/
::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  background: #dce2e9;
}

::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  background: #f7fbfb;
}

.header-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-bottom: 1px solid #efefef;
  padding: 0 0 20px;
}
.header-wrap .action .el-button + .el-button {
  margin-left: 20px;
}

.table .el-table-column--selection .cell {
  text-overflow: clip;
}
.table .el-table__header th {
  background-color: #f7fbfb !important;
  height: 50px;
  text-align: center;
  border-right: 0;
}
.table .el-table__body td {
  padding: 10px;
  border-right: 0;
}

.margin_r_5 {
  margin-right: 5px;
}

.margin_r_18 {
  margin-right: 18px;
}

.h_20 {
  height: 20px;
}

.color_999 {
  color: #999999;
}

.color_blue {
  color: #51cca3;
}

.color_red {
  color: red;
}

.c_p {
  cursor: pointer;
}

.padding_0_30 {
  padding: 0 30px;
}

.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.flex_center_ {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.flex_center_center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.el-button--text {
  color: #51cca3;
}
.el-button--text:hover {
  color: #12a272;
}

.el-loading-spinner .path {
  stroke: #51cca3;
}
.el-loading-spinner .el-loading-text {
  color: #51cca3;
}

.admin_table {
  width: 100%;
  border-spacing: 0;
  border: 1px solid #e3e3e3;
}
.admin_table tbody {
  border-top: 1px dotted #e3e3e3;
}
.admin_table tbody:first-of-type {
  border-top: none;
}
.admin_table tr {
  border-top: 1px dotted #e3e3e3;
  font-size: 14px;
}
.admin_table tr:first-child {
  border-top: none;
}
.admin_table tr th {
  height: 50px;
  vertical-align: middle;
  background: #f5f5f5;
  text-align: center;
  color: #232323;
}
.admin_table tr td {
  vertical-align: middle;
  text-align: center;
  padding: 14px 0;
  min-height: 50px;
  line-height: 22px;
  color: #232323;
}
.admin_table tr td .btn_txt {
  font-size: 14px;
  cursor: pointer;
  margin: 6px auto;
  line-height: 20px;
}
.admin_table tr td .btn_txt:hover {
  color: #238efa;
}
.admin_table tr td .btn {
  cursor: pointer;
  height: 26px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 0 auto;
  color: #232323;
  border: none;
  border-radius: 3px;
  border: 1px solid #d8d8d8;
  width: 76px;
  padding: 0;
}
.admin_table tr td .btn:hover {
  border: 1px solid #238efa;
  color: #238efa;
}
.admin_table .border_right {
  border-right: 1px dotted #e3e3e3;
}
.admin_table .border_left {
  border-left: 1px dotted #e3e3e3;
}

.el-image-viewer__wrapper .el-icon-circle-close {
  color: #fff;
}

.el-dialog {
  min-width: 600px;
}
.el-dialog .el-dialog__header {
  background-color: #f2f2f2;
  padding: 15px 20px !important;
  position: relative;
}
.el-dialog .el-dialog__header .el-dialog__headerbtn {
  top: 50% !important;
  height: 16px;
  margin-top: -8px;
}
.el-dialog .el-dialog__body {
  position: relative;
  padding: 30px;
}

.com_dialog_from_wrap {
  max-height: 60vh;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 30px 30px 0 30px;
  border-bottom: 1px solid #cdcdcd;
  margin-right: -30px;
  margin-bottom: 70px;
  margin-top: -30px;
  margin-left: -30px;
}
.com_dialog_from_wrap .el-form .el-select {
  display: block !important;
}
.com_dialog_from_wrap .el-form .el-cascader {
  display: block !important;
}
.com_dialog_from_wrap .el-form .el-form-item__label {
  color: #707070;
  line-height: 36px !important;
}
.com_dialog_from_wrap .el-form .el-form-item__content {
  line-height: 36px;
}
.com_dialog_from_wrap .el-form .el-form-item__content .el-input__inner {
  border-color: #cdcdcd;
  height: 36px;
  font-size: 14px;
}
.com_dialog_from_wrap .el-form .el-form-item__content .el-textarea__inner:hover, .com_dialog_from_wrap .el-form .el-form-item__content .el-textarea__inner:focus,
.com_dialog_from_wrap .el-form .el-form-item__content .el-input__inner:hover,
.com_dialog_from_wrap .el-form .el-form-item__content .el-input__inner:focus {
  border-color: #51cca3;
}
.com_dialog_from_wrap .el-form .el-form-item {
  margin-bottom: 30px;
}
.com_dialog_from_wrap .el-form .el-form-item .el-form-item__error {
  margin-top: 8px;
}
.com_dialog_from_wrap .el-form .el-form-item .el-radio-group,
.com_dialog_from_wrap .el-form .el-form-item .el-switch {
  margin-top: 8px;
}
.com_dialog_from_wrap .el-form .btn-group {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.com_dialog_from_wrap .el-form .btn-group .el-form-item__content {
  margin-left: 0 !important;
}
.com_dialog_from_wrap .el-form .btn-group .el-button--primary {
  width: 300px;
  height: 40px;
  background-color: #51cca3 !important;
  border-color: #51cca3 !important;
  -webkit-transition: all ease-in-out 0.5s;
  transition: all ease-in-out 0.5s;
}
.com_dialog_from_wrap .el-form .btn-group .el-button--primary:hover {
  opacity: 0.9;
}

.frame-dialog {
  top: 141px !important;
  left: 220px !important;
  right: 20px !important;
  bottom: 20px !important;
  border: 1px solid #efefef;
  -webkit-box-shadow: 1px 1px 4px #f1f1f1;
          box-shadow: 1px 1px 4px #f1f1f1;
}
.frame-dialog .el-dialog.is-fullscreen {
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.frame-dialog .el-dialog.is-fullscreen .el-dialog__header {
  background-color: #f7fbfb;
  border-bottom: 1px solid #efefef;
}
.frame-dialog .el-dialog.is-fullscreen .el-dialog__header h2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  font-size: 15px;
  margin: 0;
  padding-right: 25px;
}
.frame-dialog .el-dialog.is-fullscreen .el-dialog__header h2 span {
  font-weight: 400;
  color: #999;
  font-size: 13px;
  line-height: 14px;
}
.frame-dialog .el-dialog.is-fullscreen .el-dialog__header h2 span em {
  font-style: normal;
  color: #ff0000;
  margin: 0 3px;
}
.frame-dialog .el-dialog.is-fullscreen .el-dialog__body {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  padding: 0 !important;
  overflow-x: hidden;
  overflow-y: auto;
}
.frame-dialog .el-dialog.is-fullscreen .el-dialog__body .editor-wrap {
  margin: 0;
  padding: 0;
  overflow: hidden;
  height: 100%;
}
.frame-dialog .el-dialog.is-fullscreen .el-dialog__body .editor-wrap .el-divider .el-divider__text {
  font-weight: bold;
  font-size: 15px;
}
.frame-dialog .el-dialog.is-fullscreen .el-dialog__body .editor-wrap .law-form-common {
  height: 100%;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.frame-dialog .el-dialog.is-fullscreen .el-dialog__body .editor-wrap .law-form-common .base-form {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 15px 20px;
}
.frame-dialog .el-dialog.is-fullscreen .el-dialog__body .editor-wrap .law-form-common .base-form .el-form-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  min-height: 62px;
}
.frame-dialog .el-dialog.is-fullscreen .el-dialog__body .editor-wrap .law-form-common .base-form .el-form-item__label {
  margin-bottom: 5px;
  text-align: left;
}
.frame-dialog .el-dialog.is-fullscreen .el-dialog__body .editor-wrap .law-form-common .base-form .el-date-editor,
.frame-dialog .el-dialog.is-fullscreen .el-dialog__body .editor-wrap .law-form-common .base-form .el-select,
.frame-dialog .el-dialog.is-fullscreen .el-dialog__body .editor-wrap .law-form-common .base-form .el-date-editor,
.frame-dialog .el-dialog.is-fullscreen .el-dialog__body .editor-wrap .law-form-common .base-form .el-cascader {
  width: 100%;
}
.frame-dialog .el-dialog.is-fullscreen .el-dialog__body .editor-wrap .law-form-common .action-foot {
  min-height: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  border-top: 1px solid #efefef;
  background-color: #f7fbfb;
  padding: 5px 15px;
  position: sticky;
  bottom: 0;
}
.frame-dialog .el-dialog.is-fullscreen .el-dialog__body .editor-wrap .law-form-common .action-foot .btn-group .el-button--default:hover {
  color: #51cca3 !important;
  background-color: #fff !important;
}
.frame-dialog.slide-close {
  left: 84px !important;
}

.el-radio__input.is-checked .el-radio__inner {
  border-color: #51cca3 !important;
  background: #7c7e94 !important;
}
.el-radio__input.is-checked + .el-radio__label {
  color: #51cca3 !important;
}

.el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner {
  border-color: #51cca3 !important;
  background: #51cca3 !important;
}
.el-checkbox__input.is-checked + .el-checkbox__label, .el-checkbox__input.is-indeterminate + .el-checkbox__label {
  color: #51cca3 !important;
}
.el-checkbox__input.is-focus .el-checkbox__inner {
  border-color: #51cca3 !important;
}
.el-checkbox__input .el-checkbox__inner:hover,
.el-checkbox__input .el-checkbox__inner:focus {
  border-color: #51cca3 !important;
}

.el-switch.is-checked .el-switch__core {
  border-color: #51cca3 !important;
  background: #51cca3 !important;
}

.el-button--primary {
  background-color: #51cca3 !important;
  border-color: #51cca3 !important;
  -webkit-transition: all ease-in-out 0.5s;
  transition: all ease-in-out 0.5s;
}
.el-button--primary.is-plain {
  background-color: #fff !important;
  color: #51cca3 !important;
}
.el-button--primary:hover {
  opacity: 0.9;
}

.el-button.el-button--default:hover, .el-button.el-button--default:focus {
  color: #51cca3 !important;
  border-color: #51cca3 !important;
  background-color: #fff !important;
}
.el-button.el-button--default.is-plain:hover, .el-button.el-button--default.is-plain:focus {
  border-color: #51cca3 !important;
  color: #51cca3 !important;
}

.el-pager li {
  color: #999 !important;
  font-weight: 350;
}
.el-pager li.active, .el-pager li:hover {
  color: #51cca3 !important;
  font-weight: 500;
}

.el-select-dropdown__item.selected {
  color: #51cca3 !important;
}

.money_input input {
  text-align: left !important;
}

.el-message-box .el-message-box__input .el-input__inner,
.el-message-box .el-message-box__input .el-textarea__inner {
  border-color: #cdcdcd;
  font-size: 14px;
}
.el-message-box .el-message-box__input .el-input__inner:hover, .el-message-box .el-message-box__input .el-input__inner:focus,
.el-message-box .el-message-box__input .el-textarea__inner:hover,
.el-message-box .el-message-box__input .el-textarea__inner:focus {
  border-color: #51cca3;
}

.editor-wrap {
  padding-right: 20px;
  margin-top: -10px;
}
.editor-wrap .el-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.editor-wrap .el-form-item {
  margin-bottom: 22px;
}
.editor-wrap .el-form-item .el-form-item__error {
  padding-top: 5px;
}
.editor-wrap .el-form-item.btn-group {
  margin-bottom: 0;
}
.editor-wrap .el-form-item.btn-group .el-button {
  padding: 0 30px;
  height: 28px;
}
.editor-wrap .el-form-item.btn-group .el-button + .el-button {
  margin-left: 20px;
}

.detail-wrap h1 {
  font-size: 18px;
  margin: 0;
}
.detail-wrap h1 span {
  font-size: 13px;
  color: #999;
  margin-left: 5px;
  font-weight: 400;
}
.detail-wrap dl {
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 14px;
  height: 20px;
  margin-bottom: 24px;
}
.detail-wrap dl dt {
  padding: 8px 0;
  width: 120px;
  text-align: right;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  font-weight: bold;
}
.detail-wrap dl dd {
  padding: 5px 10px;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  margin-left: 0;
  max-height: 400px;
  overflow-x: hidden;
  overflow-y: auto;
}
.detail-wrap dl dd h2 {
  font-size: 14px;
  padding: 5px 0;
  margin-bottom: 5px;
  margin-top: 0;
}
.detail-wrap dl dd h2 span {
  margin-left: 3px;
  font-size: 12px;
  color: #999;
}
.detail-wrap dl dd .list {
  max-height: 120px;
  overflow-x: hidden;
  overflow-y: auto;
  border: 1px dashed #e2e2e2;
  padding: 2px 10px 10px;
  margin-bottom: 5px;
}
.detail-wrap dl dd .list span {
  margin-right: 8px;
  margin-top: 8px;
}
.detail-wrap dl dd .el-tree-node.is-current .el-tree-node__content {
  background-color: transparent;
}
.detail-wrap dl dd .el-tree-node .el-tree-node__content:hover, .detail-wrap dl dd .el-tree-node .el-tree-node__content:focus {
  background-color: transparent;
  color: #2f74ff;
}
.detail-wrap dl dd .el-tree-node__content .el-tree-node__expand-icon {
  display: none;
}
.detail-wrap dl dd .el-tree-node__children {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.smsCode-dialog {
  min-width: 400px;
}
.smsCode-dialog .el-dialog__body {
  padding: 15px;
}
.smsCode-dialog .el-dialog__body .mobile {
  padding: 20px 20px 10px;
}
.smsCode-dialog .el-dialog__body .mobile span {
  color: #ff4400;
}
.smsCode-dialog .el-dialog__body .smsCode-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 10px 20px 30px;
}
.smsCode-dialog .el-dialog__body .smsCode-row .send-sms-btn {
  margin-left: 15px;
}
.smsCode-dialog .el-dialog__body .smsCode-row .el-input-number {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  overflow: hidden;
}
.smsCode-dialog .el-dialog__body .smsCode-row .el-input-number.is-error .el-input__inner {
  border: 1px solid #f56c6c;
}
.smsCode-dialog .el-dialog__body .error-message {
  font-size: 13px;
  color: #f56c6c;
  padding: 0 20px;
  margin-top: -15px;
}
.smsCode-dialog .el-dialog__footer {
  padding-top: 20px;
  border-top: 1px solid #f8f8f9;
}