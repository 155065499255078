.margin_r_18[data-v-658b64e2] {
  margin-right: 18px;
}
.flex_center_[data-v-658b64e2] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.flex_center_center[data-v-658b64e2] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.pager-wrap[data-v-658b64e2] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 20px 0 0;
}
.pager-wrap[data-v-658b64e2] .el-input__inner:hover, .pager-wrap[data-v-658b64e2] .el-input__inner:active, .pager-wrap[data-v-658b64e2] .el-input__inner:focus {
  border-color: #51cca3;
}
.pager-wrap[data-v-658b64e2] .btn-prev,
.pager-wrap[data-v-658b64e2] .btn-next {
  outline: none;
}
.pager-wrap[data-v-658b64e2] .btn-prev .el-icon,
.pager-wrap[data-v-658b64e2] .btn-next .el-icon {
  outline: none;
  color: #666 !important;
}
.pager-wrap[data-v-658b64e2] .btn-prev:hover .el-icon,
.pager-wrap[data-v-658b64e2] .btn-next:hover .el-icon {
  color: #51cca3 !important;
}