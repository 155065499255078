.margin_r_18[data-v-5038cd2e] {
  margin-right: 18px;
}
.flex_center_[data-v-5038cd2e] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.flex_center_center[data-v-5038cd2e] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.quill-editor .quill-upload[data-v-5038cd2e] {
  height: 0 !important;
  overflow: hidden;
  line-height: 0 !important;
}