.margin_r_18[data-v-2ba531d6] {
  margin-right: 18px;
}
.flex_center_[data-v-2ba531d6] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.flex_center_center[data-v-2ba531d6] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.filter-wrap[data-v-2ba531d6] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.filter-wrap .el-input[data-v-2ba531d6] {
  width: 220px;
}
.filter-wrap .el-button[data-v-2ba531d6] {
  margin-left: 20px;
}
.filter-wrap[data-v-2ba531d6] .el-input__inner {
  border: 1px solid #cdcdcd !important;
}
.filter-wrap[data-v-2ba531d6] .el-input__inner:hover, .filter-wrap[data-v-2ba531d6] .el-input__inner:focus {
  border: 1px solid #51cca3 !important;
}