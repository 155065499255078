.margin_r_18 {
  margin-right: 18px;
}
.flex_center_ {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.flex_center_center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.upload-demo_dialog .el-dialog__body {
  padding: 20px;
}
.upload-demo_dialog .el-dialog__body .el-upload {
  display: block;
}
.upload-demo_dialog .el-dialog__body .el-upload .el-upload-dragger {
  width: 100%;
}
.upload-demo_dialog .el-upload-list {
  display: none;
}
.upload-demo_dialog .color_red {
  color: #f56c6c;
}
.upload-demo_dialog .color_green {
  color: #67c23a;
}