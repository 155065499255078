.theme-global .global-theme-container .global-theme-container-main .global-theme-container-main-header .global-multiple-page-control .el-tabs__nav .el-tabs__item, .theme-global .el-menu-item, .theme-global .el-submenu__title, .global-layout-header-aside-group .global-layout-header-aside-content .global-theme-container .global-theme-container-aside .global-layout-header-aside-menu-empty, .global-layout-header-aside-group .global-layout-header-aside-content .global-theme-container .global-theme-container-aside .el-submenu, .global-layout-header-aside-group .global-layout-header-aside-content .global-theme-container .global-theme-container-aside .el-menu, .global-layout-header-aside-group .global-layout-header-aside-content .global-theme-header .global-header-right .el-dropdown, .global-layout-header-aside-group .global-layout-header-aside-content .global-theme-header .el-menu .el-submenu, .global-layout-header-aside-group .global-layout-header-aside-content .global-theme-header .el-menu .el-menu-item, .global-layout-header-aside-group .global-layout-header-aside-content .global-theme-header .toggle-aside-btn {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer;
}
.global-layout-header-aside-group .global-layout-header-aside-content .global-theme-container .global-theme-container-main .global-theme-container-main-body .container-component, .global-layout-header-aside-group .global-layout-header-aside-content .global-theme-container .global-theme-container-aside .global-layout-header-aside-menu-side, .global-layout-header-aside-group .global-layout-header-aside-content, .global-layout-header-aside-group .global-layout-header-aside-mask {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}
.margin_r_18 {
  margin-right: 18px;
}
.flex_center_ {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.flex_center_center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.el-menu--popup .el-menu-item {
  height: 36px;
  line-height: 36px;
}
.el-menu--popup .el-submenu__title {
  height: 36px;
  line-height: 36px;
}
.global-multiple-page-control-btn .el-button:focus,
.global-multiple-page-control-btn .el-button:hover {
  color: #51cca3;
  border: 0;
  background-color: rgba(81, 204, 163, 0.1);
}
.el-dropdown-menu__item:focus,
.el-dropdown-menu__item:not(.is-disabled):hover {
  color: #51cca3 !important;
  background-color: rgba(81, 204, 163, 0.1) !important;
}
.global-layout-header-aside-group {
  height: 100%;
  width: 100%;
  min-width: 900px;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  position: relative;
}
.global-layout-header-aside-group .global-layout-header-aside-content .global-theme-header {
  height: 64px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-bottom: 1px solid #efefef;
}
.global-layout-header-aside-group .global-layout-header-aside-content .global-theme-header .logo-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-left: 30px;
}
.global-layout-header-aside-group .global-layout-header-aside-content .global-theme-header .logo-wrap .txt {
  height: 20px;
  margin-left: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #232323;
  font-size: 16px;
}
.global-layout-header-aside-group .global-layout-header-aside-content .global-theme-header .logo-wrap .txt:before {
  content: "";
  width: 1px;
  height: 20px;
  background-color: #232323;
  display: block;
  margin-right: 30px;
}
.global-layout-header-aside-group .global-layout-header-aside-content .global-theme-header .global-theme-header-menu {
  overflow: hidden;
}
.global-layout-header-aside-group .global-layout-header-aside-content .global-theme-header .global-theme-header-menu.is-scrollable {
  position: relative;
  padding: 0 20px;
}
.global-layout-header-aside-group .global-layout-header-aside-content .global-theme-header .global-theme-header-menu.is-scrollable .global-theme-header-menu__prev,
.global-layout-header-aside-group .global-layout-header-aside-content .global-theme-header .global-theme-header-menu.is-scrollable .global-theme-header-menu__next {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.global-layout-header-aside-group .global-layout-header-aside-content .global-theme-header .global-theme-header-menu .global-theme-header-menu__content {
  overflow: hidden;
}
.global-layout-header-aside-group .global-layout-header-aside-content .global-theme-header .global-theme-header-menu .global-theme-header-menu__content .global-theme-header-menu__scroll {
  white-space: nowrap;
  position: relative;
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
  float: left;
}
.global-layout-header-aside-group .global-layout-header-aside-content .global-theme-header .global-theme-header-menu .global-theme-header-menu__prev,
.global-layout-header-aside-group .global-layout-header-aside-content .global-theme-header .global-theme-header-menu .global-theme-header-menu__next {
  height: 60px;
  position: absolute;
  top: 0;
  font-size: 20px;
  cursor: pointer;
  display: none;
}
.global-layout-header-aside-group .global-layout-header-aside-content .global-theme-header .global-theme-header-menu .global-theme-header-menu__prev {
  left: 0;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
.global-layout-header-aside-group .global-layout-header-aside-content .global-theme-header .global-theme-header-menu .global-theme-header-menu__next {
  right: 0;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}
.global-layout-header-aside-group .global-layout-header-aside-content .global-theme-container .global-theme-container-aside {
  -webkit-transition: width 0.3s;
  transition: width 0.3s;
  position: relative;
  border-right: 1px solid #efefef;
}
.global-layout-header-aside-group .global-layout-header-aside-content .global-theme-container .global-theme-container-aside .global-layout-header-aside-menu-side {
  overflow: hidden;
  background-color: #fff;
}
.global-layout-header-aside-group .global-layout-header-aside-content .global-theme-container .global-theme-container-aside .global-layout-header-aside-menu-side .el-menu-item i {
  margin-right: 15px;
}
.global-layout-header-aside-group .global-layout-header-aside-content .global-theme-container .global-theme-container-aside .global-layout-header-aside-menu-side .el-submenu .el-submenu__title i {
  margin-right: 15px;
}
.global-layout-header-aside-group .global-layout-header-aside-content .global-theme-container .global-theme-container-aside .global-layout-header-aside-menu-side .el-submenu .el-icon-arrow-down {
  margin-top: -6px !important;
}
.global-layout-header-aside-group .global-layout-header-aside-content .global-theme-container .global-theme-container-aside .global-layout-header-aside-menu-side .el-submenu.is-opened .el-submenu__title i,
.global-layout-header-aside-group .global-layout-header-aside-content .global-theme-container .global-theme-container-aside .global-layout-header-aside-menu-side .el-submenu.is-opened .el-submenu__title span {
  color: #51cca3;
  font-weight: 400;
}
.global-layout-header-aside-group .global-layout-header-aside-content .global-theme-container .global-theme-container-aside .global-layout-header-aside-menu-side .el-menu--inline {
  background-color: #f4fcfc;
  border-left: 3px solid #51cca3;
}
.global-layout-header-aside-group .global-layout-header-aside-content .global-theme-container .global-theme-container-aside .global-layout-header-aside-menu-side .el-menu--inline .el-menu-item {
  padding-left: 20px !important;
}
.global-layout-header-aside-group .global-layout-header-aside-content .global-theme-container .global-theme-container-main {
  padding: 0px;
  position: relative;
  overflow: hidden;
}
.global-layout-header-aside-group .global-layout-header-aside-content .global-theme-container .global-theme-container-main .global-theme-container-main-layer {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: #f8f8f9;
}
.global-layout-header-aside-group .global-layout-header-aside-content .global-theme-container .global-theme-container-main .global-theme-container-main-body {
  position: relative;
}
.global-layout-header-aside-group.grayMode {
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: grayscale(100%);
  -webkit-filter: gray;
          filter: gray;
}
.global-layout-header-aside-group .global-layout-header-aside-content .global-theme-header .logo-group {
  -webkit-transition: width 0.3s;
  transition: width 0.3s;
  float: left;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.global-layout-header-aside-group .global-layout-header-aside-content .global-theme-header .logo-group img {
  height: 40px;
}
.global-layout-header-aside-group .global-layout-header-aside-content .global-theme-header .toggle-aside-btn {
  float: left;
  height: 60px;
  width: 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.global-layout-header-aside-group .global-layout-header-aside-content .global-theme-header .toggle-aside-btn i {
  font-size: 20px;
  margin-top: 4px;
}
.global-layout-header-aside-group .global-layout-header-aside-content .global-theme-header .el-menu {
  float: left;
  border-bottom: none;
  background-color: transparent;
}
.global-layout-header-aside-group .global-layout-header-aside-content .global-theme-header .el-menu .el-submenu i.fa, .global-layout-header-aside-group .global-layout-header-aside-content .global-theme-header .el-menu .el-menu-item i.fa {
  font-size: 16px;
  margin-right: 4px;
}
.global-layout-header-aside-group .global-layout-header-aside-content .global-theme-header .el-menu .el-menu-item {
  border-bottom: none;
}
.global-layout-header-aside-group .global-layout-header-aside-content .global-theme-header .el-menu .el-submenu .el-submenu__title {
  border-bottom: none;
}
.global-layout-header-aside-group .global-layout-header-aside-content .global-theme-header .global-header-right {
  float: right;
  height: 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.global-layout-header-aside-group .global-layout-header-aside-content .global-theme-header .global-header-right .btn-text {
  padding: 14px 12px;
  border-radius: 4px;
  margin: 0px !important;
  color: #232323;
}
.global-layout-header-aside-group .global-layout-header-aside-content .global-theme-header .global-header-right .btn-text.el-color-picker.el-color-picker--mini {
  padding: 9px 6px;
}
.global-layout-header-aside-group .global-layout-header-aside-content .global-theme-container .global-theme-container-aside .el-submenu .el-submenu__title i, .global-layout-header-aside-group .global-layout-header-aside-content .global-theme-container .global-theme-container-aside .el-menu .el-menu-item i {
  width: 20px;
  text-align: center;
  font-size: 16px;
}
.global-layout-header-aside-group .global-layout-header-aside-content .global-theme-container .global-theme-container-aside .el-menu {
  background-color: transparent;
  border-right: none;
}
.global-layout-header-aside-group .global-layout-header-aside-content .global-theme-container .global-theme-container-aside .el-submenu .el-submenu__title .el-submenu__icon-arrow {
  margin-top: -10px;
}
.global-layout-header-aside-group .global-layout-header-aside-content .global-theme-container .global-theme-container-aside .global-layout-header-aside-menu-empty {
  height: 160px;
  margin: 10px;
  margin-top: 0px;
  border-radius: 4px;
}
.global-layout-header-aside-group .global-layout-header-aside-content .global-theme-container .global-theme-container-aside .global-layout-header-aside-menu-empty i {
  font-size: 30px;
  margin-bottom: 10px;
}
.global-layout-header-aside-group .global-layout-header-aside-content .global-theme-container .global-theme-container-aside .global-layout-header-aside-menu-empty span {
  font-size: 14px;
}
.global-layout-header-aside-group .global-layout-header-aside-content .global-theme-container .global-theme-container-aside .el-menu--collapse {
  background-color: transparent;
}
.global-layout-header-aside-group .global-layout-header-aside-content .global-theme-container .global-theme-container-aside .el-menu--collapse .el-submenu__title {
  text-align: center;
}
.global-layout-header-aside-group .global-layout-header-aside-content .global-theme-container .global-theme-container-main .global-theme-container-main-header {
  height: 56px;
  background-color: #fff;
  margin-bottom: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.global-layout-header-aside-group .global-layout-header-aside-content .global-theme-container .global-theme-container-main .global-theme-container-main-header .toggle-aside-btn {
  width: 56px;
  height: 56px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  cursor: pointer;
}
.global-layout-header-aside-group .global-layout-header-aside-content .global-theme-container .global-theme-container-main .global-theme-container-main-header .toggle-aside-btn i {
  font-size: 26px;
  color: #51cca3;
}
.global-layout-header-aside-group .global-layout-header-aside-content .global-theme-container .global-theme-container-main .global-theme-container-main-header .global-multiple-page-control-group {
  padding-right: 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  overflow: hidden;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.global-layout-header-aside-group .global-layout-header-aside-content .global-theme-container .global-theme-container-main .global-theme-container-main-header .global-multiple-page-control-group .global-multiple-page-control-content {
  overflow: auto;
  position: relative;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.global-layout-header-aside-group .global-layout-header-aside-content .global-theme-container .global-theme-container-main .global-theme-container-main-header .global-multiple-page-control-group .global-multiple-page-control-content .global-multiple-page-control-content-inner .global-multiple-page-control .el-tabs__header.is-top {
  margin: 0px;
}
.global-layout-header-aside-group .global-layout-header-aside-content .global-theme-container .global-theme-container-main .global-theme-container-main-header .global-multiple-page-control-group .global-multiple-page-control-content .global-multiple-page-control-content-inner .global-multiple-page-control .el-tabs__nav {
  overflow: hidden;
}
.global-layout-header-aside-group .global-layout-header-aside-content .global-theme-container .global-theme-container-main .global-theme-container-main-header .global-multiple-page-control-group .global-multiple-page-control-btn {
  margin-left: 10px;
  width: 82px;
}
.global-layout-header-aside-group .global-layout-header-aside-content .global-theme-container .global-theme-container-main .global-theme-container-main-header .global-multiple-page-control-group .global-multiple-page-control-btn .el-dropdown .el-button-group .el-button:first-child {
  border-bottom-left-radius: 0px;
}
.global-layout-header-aside-group .global-layout-header-aside-content .global-theme-container .global-theme-container-main .global-theme-container-main-header .global-multiple-page-control-group .global-multiple-page-control-btn .el-dropdown .el-button-group .el-button:last-child {
  border-bottom-right-radius: 0px;
}
.global-layout-header-aside-group .global-layout-header-aside-content .global-theme-container .global-theme-container-main .global-theme-container-main-body .container-component {
  overflow: hidden;
}
.global-layout-header-aside-group .global-layout-header-aside-content .global-theme-container .global-theme-container-main .global-theme-container-main-body .container-component .global-container-full {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  overflow: hidden;
}
.global-layout-header-aside-group .global-layout-header-aside-content .global-theme-container .global-theme-container-main .global-theme-container-main-body .container-component .global-container-full .global-container-full__header {
  padding: 20px;
}
.global-layout-header-aside-group .global-layout-header-aside-content .global-theme-container .global-theme-container-main .global-theme-container-main-body .container-component .global-container-full .global-container-full__body {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  height: 100%;
  padding: 20px 20px;
  overflow: auto;
  position: relative;
}
.global-layout-header-aside-group .global-layout-header-aside-content .global-theme-container .global-theme-container-main .global-theme-container-main-body .container-component .global-container-full .global-container-full__footer {
  padding: 20px;
}
.global-layout-header-aside-group .global-layout-header-aside-content .global-theme-container .global-theme-container-main .global-theme-container-main-body .container-component .global-container-full-bs {
  position: absolute;
  top: 0px;
  right: 20px;
  bottom: 0px;
  left: 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  overflow: hidden;
}
.global-layout-header-aside-group .global-layout-header-aside-content .global-theme-container .global-theme-container-main .global-theme-container-main-body .container-component .global-container-full-bs .global-container-full-bs__header {
  padding: 20px;
}
.global-layout-header-aside-group .global-layout-header-aside-content .global-theme-container .global-theme-container-main .global-theme-container-main-body .container-component .global-container-full-bs .global-container-full-bs__body {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  overflow: hidden;
  position: relative;
}
.global-layout-header-aside-group .global-layout-header-aside-content .global-theme-container .global-theme-container-main .global-theme-container-main-body .container-component .global-container-full-bs .global-container-full-bs__body .global-container-full-bs__body-wrapper-inner {
  padding: 20px;
  position: relative;
}
.global-layout-header-aside-group .global-layout-header-aside-content .global-theme-container .global-theme-container-main .global-theme-container-main-body .container-component .global-container-full-bs .global-container-full-bs__footer {
  padding: 20px;
}
.global-layout-header-aside-group .global-layout-header-aside-content .global-theme-container .global-theme-container-main .global-theme-container-main-body .container-component .global-container-ghost {
  position: absolute;
  top: 0px;
  right: 20px;
  bottom: 0px;
  left: 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  overflow: hidden;
}
.global-layout-header-aside-group .global-layout-header-aside-content .global-theme-container .global-theme-container-main .global-theme-container-main-body .container-component .global-container-ghost .global-container-ghost__header {
  padding: 20px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.global-layout-header-aside-group .global-layout-header-aside-content .global-theme-container .global-theme-container-main .global-theme-container-main-body .container-component .global-container-ghost .global-container-ghost__body {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  overflow: auto;
  position: relative;
}
.global-layout-header-aside-group .global-layout-header-aside-content .global-theme-container .global-theme-container-main .global-theme-container-main-body .container-component .global-container-ghost .global-container-ghost__footer {
  padding: 20px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.global-layout-header-aside-group .global-layout-header-aside-content .global-theme-container .global-theme-container-main .global-theme-container-main-body .container-component .global-container-ghost-bs {
  position: absolute;
  top: 0px;
  right: 20px;
  bottom: 0px;
  left: 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  overflow: hidden;
}
.global-layout-header-aside-group .global-layout-header-aside-content .global-theme-container .global-theme-container-main .global-theme-container-main-body .container-component .global-container-ghost-bs .global-container-ghost-bs__header {
  padding: 20px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.global-layout-header-aside-group .global-layout-header-aside-content .global-theme-container .global-theme-container-main .global-theme-container-main-body .container-component .global-container-ghost-bs .global-container-ghost-bs__body {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  overflow: hidden;
  position: relative;
}
.global-layout-header-aside-group .global-layout-header-aside-content .global-theme-container .global-theme-container-main .global-theme-container-main-body .container-component .global-container-ghost-bs .global-container-ghost-bs__footer {
  padding: 20px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.global-layout-header-aside-group .global-layout-header-aside-content .global-theme-container .global-theme-container-main .global-theme-container-main-body .container-component .global-container-card {
  position: absolute;
  top: 0px;
  right: 20px;
  bottom: 0px;
  left: 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  overflow: hidden;
}
.global-layout-header-aside-group .global-layout-header-aside-content .global-theme-container .global-theme-container-main .global-theme-container-main-body .container-component .global-container-card .global-container-card__header {
  padding: 20px;
}
.global-layout-header-aside-group .global-layout-header-aside-content .global-theme-container .global-theme-container-main .global-theme-container-main-body .container-component .global-container-card .global-container-card__body {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  overflow: auto;
}
.global-layout-header-aside-group .global-layout-header-aside-content .global-theme-container .global-theme-container-main .global-theme-container-main-body .container-component .global-container-card .global-container-card__body .global-container-card__body-card {
  position: relative;
  margin-bottom: 20px;
  padding: 20px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.global-layout-header-aside-group .global-layout-header-aside-content .global-theme-container .global-theme-container-main .global-theme-container-main-body .container-component .global-container-card .global-container-card__footer {
  padding: 20px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.global-layout-header-aside-group .global-layout-header-aside-content .global-theme-container .global-theme-container-main .global-theme-container-main-body .container-component .global-container-card-bs {
  position: absolute;
  top: 0px;
  right: 20px;
  bottom: 0px;
  left: 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  overflow: hidden;
}
.global-layout-header-aside-group .global-layout-header-aside-content .global-theme-container .global-theme-container-main .global-theme-container-main-body .container-component .global-container-card-bs .global-container-card-bs__header {
  padding: 20px;
}
.global-layout-header-aside-group .global-layout-header-aside-content .global-theme-container .global-theme-container-main .global-theme-container-main-body .container-component .global-container-card-bs .global-container-card-bs__body {
  position: relative;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  overflow: hidden;
}
.global-layout-header-aside-group .global-layout-header-aside-content .global-theme-container .global-theme-container-main .global-theme-container-main-body .container-component .global-container-card-bs .global-container-card-bs__body .global-container-card-bs__body-wrapper-inner {
  padding-bottom: 20px;
}
.global-layout-header-aside-group .global-layout-header-aside-content .global-theme-container .global-theme-container-main .global-theme-container-main-body .container-component .global-container-card-bs .global-container-card-bs__body .global-container-card-bs__body-card {
  position: relative;
  padding: 20px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.global-layout-header-aside-group .global-layout-header-aside-content .global-theme-container .global-theme-container-main .global-theme-container-main-body .container-component .global-container-card-bs .global-container-card-bs__footer {
  padding: 20px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.el-message-box__wrapper .el-message-box {
  width: 340px;
  padding: 0;
}
.el-message-box__wrapper .el-message-box .el-message-box__header {
  padding: 15px;
}
.el-message-box__wrapper .el-message-box .el-message-box__header .el-message-box__title {
  font-size: 15px;
}
.el-message-box__wrapper .el-message-box .el-message-box__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 30px 15px;
}
.el-message-box__wrapper .el-message-box .el-message-box__btns {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 20px;
}
.el-message-box__wrapper .el-message-box .el-message-box__btns .el-button + .el-button {
  margin-left: 15px;
}
.theme-global .el-card.global-card {
  border: 1px solid #cfd7e5;
}
.theme-global .el-card.global-card .el-card__header {
  border-bottom: 1px solid #cfd7e5;
}
.theme-global .global-layout-header-aside-group {
  background-color: #fff;
}
.theme-global .global-layout-header-aside-group .global-layout-header-aside-mask {
  background: rgba(0, 0, 0, 0);
}
.theme-global .el-menu-item i, .theme-global .el-submenu__title i {
  display: inline-block;
  width: 14px;
  text-align: center;
  margin-right: 5px;
}
.theme-global .el-menu-item svg, .theme-global .el-submenu__title svg {
  margin: 0px;
  height: 14px;
  width: 14px;
  margin-right: 5px;
}
.theme-global .el-submenu__title:hover {
  color: #293849;
  background: #ecf5ff;
}
.theme-global .el-submenu__title:hover i.fa {
  color: #293849;
}
.theme-global .el-menu-item:hover {
  color: #293849;
  background: #ecf5ff;
}
.theme-global .el-menu-item:hover i.fa {
  color: #293849;
}
.theme-global .el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
  color: #293849;
  background: #ecf5ff;
}
.theme-global .el-menu--horizontal .el-menu-item:not(.is-disabled):hover i.fa {
  color: #293849;
}
.theme-global .el-menu--horizontal .el-menu .el-submenu__title:hover {
  color: #293849;
  background: #ecf5ff;
}
.theme-global .el-menu--horizontal .el-menu .el-submenu__title:hover i.fa {
  color: #293849;
}
.theme-global .global-theme-header .global-theme-header-menu .global-theme-header-menu__prev,
.theme-global .global-theme-header .global-theme-header-menu .global-theme-header-menu__next {
  color: #606266;
  background: transparent;
}
.theme-global .global-theme-header .global-theme-header-menu .global-theme-header-menu__prev:hover,
.theme-global .global-theme-header .global-theme-header-menu .global-theme-header-menu__next:hover {
  color: #51cca3;
  background: rgba(255, 255, 255, 0);
}
.theme-global .global-theme-header .toggle-aside-btn i {
  color: #606266;
  background: transparent;
}
.theme-global .global-theme-header .toggle-aside-btn i:hover {
  color: #51cca3;
}
.theme-global .global-theme-header .el-menu .el-menu-item {
  -webkit-transition: border-top-color 0s;
  transition: border-top-color 0s;
  color: #606266;
  background: transparent;
}
.theme-global .global-theme-header .el-menu .el-menu-item i.fa {
  color: inherit;
}
.theme-global .global-theme-header .el-menu .el-menu-item:hover {
  color: #51cca3;
  background: rgba(255, 255, 255, 0);
}
.theme-global .global-theme-header .el-menu .el-menu-item:hover i.fa {
  color: inherit;
}
.theme-global .global-theme-header .el-menu .el-menu-item:focus {
  color: #51cca3;
  background: rgba(255, 255, 255, 0);
}
.theme-global .global-theme-header .el-menu .el-menu-item:focus i.fa {
  color: inherit;
}
.theme-global .global-theme-header .el-menu .el-menu-item.is-active {
  color: #51cca3;
  background: rgba(255, 255, 255, 0);
}
.theme-global .global-theme-header .el-menu .el-menu-item.is-active i.fa {
  color: inherit;
}
.theme-global .global-theme-header .el-menu .el-submenu.is-active .el-submenu__title {
  color: #51cca3;
  background: rgba(255, 255, 255, 0);
}
.theme-global .global-theme-header .el-menu .el-submenu.is-active .el-submenu__title i.fa {
  color: inherit;
}
.theme-global .global-theme-header .el-menu .el-submenu .el-submenu__title {
  -webkit-transition: border-top-color 0s;
  transition: border-top-color 0s;
  color: #606266;
  background: transparent;
}
.theme-global .global-theme-header .el-menu .el-submenu .el-submenu__title i.fa {
  color: inherit;
}
.theme-global .global-theme-header .el-menu .el-submenu .el-submenu__title .el-submenu__icon-arrow {
  color: #606266;
}
.theme-global .global-theme-header .el-menu .el-submenu .el-submenu__title:hover {
  color: #51cca3;
  background: rgba(255, 255, 255, 0);
}
.theme-global .global-theme-header .el-menu .el-submenu .el-submenu__title:hover i.fa {
  color: inherit;
}
.theme-global .global-theme-header .el-menu .el-submenu .el-submenu__title:hover .el-submenu__icon-arrow {
  color: #51cca3;
}
.theme-global .global-theme-header .el-menu .el-submenu .el-submenu__title:focus {
  color: #51cca3;
  background: rgba(255, 255, 255, 0);
}
.theme-global .global-theme-header .el-menu .el-submenu .el-submenu__title:focus i.fa {
  color: inherit;
}
.theme-global .global-theme-header .el-menu .el-submenu .el-submenu__title:focus .el-submenu__icon-arrow {
  color: #51cca3;
}
.theme-global .global-theme-header .global-header-right .btn-text {
  color: #606266;
}
.theme-global .global-theme-header .global-header-right .btn-text.can-hover:hover {
  color: #51cca3;
  background: rgba(255, 255, 255, 0);
}
.theme-global .global-theme-container .global-theme-container-aside .global-layout-header-aside-menu-empty {
  background: rgba(0, 0, 0, 0.03);
}
.theme-global .global-theme-container .global-theme-container-aside .global-layout-header-aside-menu-empty i {
  color: #606266;
}
.theme-global .global-theme-container .global-theme-container-aside .global-layout-header-aside-menu-empty span {
  color: #606266;
}
.theme-global .global-theme-container .global-theme-container-aside .global-layout-header-aside-menu-empty:hover {
  background: rgba(0, 0, 0, 0.05);
}
.theme-global .global-theme-container .global-theme-container-aside .global-layout-header-aside-menu-empty:hover i {
  color: #303133;
}
.theme-global .global-theme-container .global-theme-container-aside .global-layout-header-aside-menu-empty:hover span {
  color: #303133;
}
.theme-global .global-theme-container .global-theme-container-aside .el-menu .el-menu-item {
  color: #595959;
  background: transparent;
}
.theme-global .global-theme-container .global-theme-container-aside .el-menu .el-menu-item i {
  color: #595959;
}
.theme-global .global-theme-container .global-theme-container-aside .el-menu .el-menu-item:hover {
  color: #51cca3;
  fill: #51cca3;
  background: rgba(255, 255, 255, 0);
}
.theme-global .global-theme-container .global-theme-container-aside .el-menu .el-menu-item:hover i {
  color: #51cca3;
}
.theme-global .global-theme-container .global-theme-container-aside .el-menu .el-menu-item:focus {
  color: #51cca3;
  fill: #51cca3;
  background: rgba(255, 255, 255, 0);
}
.theme-global .global-theme-container .global-theme-container-aside .el-menu .el-menu-item:focus i {
  color: #51cca3;
}
.theme-global .global-theme-container .global-theme-container-aside .el-menu .el-menu-item.is-active {
  color: #51cca3;
  fill: #51cca3;
  background: rgba(255, 255, 255, 0);
}
.theme-global .global-theme-container .global-theme-container-aside .el-menu .el-menu-item.is-active i {
  color: #51cca3;
}
.theme-global .global-theme-container .global-theme-container-aside .el-submenu .el-submenu__title {
  color: #595959;
  background: transparent;
}
.theme-global .global-theme-container .global-theme-container-aside .el-submenu .el-submenu__title i {
  color: #595959;
}
.theme-global .global-theme-container .global-theme-container-aside .el-submenu .el-submenu__title .el-submenu__icon-arrow {
  color: #595959;
}
.theme-global .global-theme-container .global-theme-container-aside .el-submenu .el-submenu__title:hover {
  color: #51cca3;
  background: rgba(255, 255, 255, 0);
}
.theme-global .global-theme-container .global-theme-container-aside .el-submenu .el-submenu__title:hover i {
  color: #51cca3;
}
.theme-global .global-theme-container .global-theme-container-aside .el-submenu .el-submenu__title:hover .el-submenu__icon-arrow {
  color: #51cca3;
}
.theme-global .global-theme-container .global-theme-container-main .global-theme-container-main-header {
  background-color: #fff;
}
.theme-global .global-theme-container .global-theme-container-main .global-theme-container-main-header .global-multiple-page-control .el-tabs__header.is-top {
  border-bottom-color: #fff;
}
.theme-global .global-theme-container .global-theme-container-main .global-theme-container-main-header .global-multiple-page-control .el-tabs__nav {
  border-color: #fff;
}
.theme-global .global-theme-container .global-theme-container-main .global-theme-container-main-header .global-multiple-page-control .el-tabs__nav .el-tabs__item {
  color: #373952;
  background-color: #fff;
  border-left-color: #fff;
}
.theme-global .global-theme-container .global-theme-container-main .global-theme-container-main-header .global-multiple-page-control .el-tabs__nav .el-tabs__item:first-child {
  border-left: none;
}
.theme-global .global-theme-container .global-theme-container-main .global-theme-container-main-header .global-multiple-page-control .el-tabs__nav .el-tabs__item:first-child:hover {
  padding: 0px 20px;
}
.theme-global .global-theme-container .global-theme-container-main .global-theme-container-main-header .global-multiple-page-control .el-tabs__nav .el-tabs__item:first-child .el-icon-close {
  display: none;
}
.theme-global .global-theme-container .global-theme-container-main .global-theme-container-main-header .global-multiple-page-control .el-tabs__nav .el-tabs__item.is-active {
  border-bottom-width: 2px;
  color: #373952;
  background-color: rgba(202, 241, 238, 0.2);
  border-bottom-color: #51cca3;
}
.theme-global .global-theme-container .global-theme-container-main .global-theme-container-main-header .global-multiple-page-control .el-tabs__nav-next, .theme-global .global-theme-container .global-theme-container-main .global-theme-container-main-header .global-multiple-page-control .el-tabs__nav-prev {
  font-size: 20px;
}
.theme-global .global-theme-container .global-theme-container-main .global-theme-container-main-header .global-multiple-page-control .el-tabs__nav-prev {
  color: #cfd7e5;
}
.theme-global .global-theme-container .global-theme-container-main .global-theme-container-main-header .global-multiple-page-control .el-tabs__nav-next {
  color: #cfd7e5;
}
.theme-global .global-theme-container .global-theme-container-main .global-theme-container-main-header .global-multiple-page-control-btn .el-dropdown .el-button-group {
  border: 0;
}
.theme-global .global-theme-container .global-theme-container-main .global-theme-container-main-header .global-multiple-page-control-btn .el-dropdown .el-button-group .el-button {
  border: 0;
  border-color: #fff;
}
.theme-global .global-theme-container .global-theme-container-main .global-theme-container-main-body .container-component .global-container-full {
  border-top: none;
  border-bottom: none;
}
.theme-global .global-theme-container .global-theme-container-main .global-theme-container-main-body .container-component .global-container-full .global-container-full__header {
  border-bottom: 1px solid #cfd7e5;
  background: #fff;
}
.theme-global .global-theme-container .global-theme-container-main .global-theme-container-main-body .container-component .global-container-full .global-container-full__body {
  background: white;
}
.theme-global .global-theme-container .global-theme-container-main .global-theme-container-main-body .container-component .global-container-full .global-container-full__footer {
  border-top: 1px solid #cfd7e5;
  background: #fff;
}
.theme-global .global-theme-container .global-theme-container-main .global-theme-container-main-body .container-component .global-container-full-bs {
  border: 1px solid #cfd7e5;
  border-top: none;
  border-bottom: none;
}
.theme-global .global-theme-container .global-theme-container-main .global-theme-container-main-body .container-component .global-container-full-bs .global-container-full-bs__header {
  border-bottom: 1px solid #cfd7e5;
  background: #fff;
}
.theme-global .global-theme-container .global-theme-container-main .global-theme-container-main-body .container-component .global-container-full-bs .global-container-full-bs__body {
  background: white;
}
.theme-global .global-theme-container .global-theme-container-main .global-theme-container-main-body .container-component .global-container-full-bs .global-container-full-bs__footer {
  border-top: 1px solid #cfd7e5;
  background: #fff;
}
.theme-global .global-theme-container .global-theme-container-main .global-theme-container-main-body .container-component .global-container-ghost .global-container-ghost__header {
  border-bottom: 1px solid #cfd7e5;
  border-left: 1px solid #cfd7e5;
  border-right: 1px solid #cfd7e5;
  background: #fff;
}
.theme-global .global-theme-container .global-theme-container-main .global-theme-container-main-body .container-component .global-container-ghost .global-container-ghost__footer {
  border-top: 1px solid #cfd7e5;
  border-left: 1px solid #cfd7e5;
  border-right: 1px solid #cfd7e5;
  background: #fff;
}
.theme-global .global-theme-container .global-theme-container-main .global-theme-container-main-body .container-component .global-container-ghost-bs .global-container-ghost-bs__header {
  border-bottom: 1px solid #cfd7e5;
  border-left: 1px solid #cfd7e5;
  border-right: 1px solid #cfd7e5;
  background: #fff;
}
.theme-global .global-theme-container .global-theme-container-main .global-theme-container-main-body .container-component .global-container-ghost-bs .global-container-ghost-bs__footer {
  border-top: 1px solid #cfd7e5;
  border-left: 1px solid #cfd7e5;
  border-right: 1px solid #cfd7e5;
  background: #fff;
}
.theme-global .global-theme-container .global-theme-container-main .global-theme-container-main-body .container-component .global-container-card .global-container-card__header {
  border-bottom: 1px solid #cfd7e5;
  border-left: 1px solid #cfd7e5;
  border-right: 1px solid #cfd7e5;
  background: #fff;
}
.theme-global .global-theme-container .global-theme-container-main .global-theme-container-main-body .container-component .global-container-card .global-container-card__body .global-container-card__body-card {
  background: white;
  border-left: 1px solid #cfd7e5;
  border-right: 1px solid #cfd7e5;
  border-bottom: 1px solid #cfd7e5;
}
.theme-global .global-theme-container .global-theme-container-main .global-theme-container-main-body .container-component .global-container-card .global-container-card__footer {
  border-top: 1px solid #cfd7e5;
  border-left: 1px solid #cfd7e5;
  border-right: 1px solid #cfd7e5;
  background: #fff;
}
.theme-global .global-theme-container .global-theme-container-main .global-theme-container-main-body .container-component .global-container-card-bs .global-container-card-bs__header {
  border-bottom: 1px solid #cfd7e5;
  border-left: 1px solid #cfd7e5;
  border-right: 1px solid #cfd7e5;
  background: #fff;
}
.theme-global .global-theme-container .global-theme-container-main .global-theme-container-main-body .container-component .global-container-card-bs .global-container-card-bs__body .global-container-card-bs__body-card {
  background: white;
  border-left: 1px solid #cfd7e5;
  border-right: 1px solid #cfd7e5;
  border-bottom: 1px solid #cfd7e5;
}
.theme-global .global-theme-container .global-theme-container-main .global-theme-container-main-body .container-component .global-container-card-bs .global-container-card-bs__footer {
  border-top: 1px solid #cfd7e5;
  border-left: 1px solid #cfd7e5;
  border-right: 1px solid #cfd7e5;
  background: #fff;
}