.panel-search .panel-search__input-group .panel-search__tip[data-v-7de463a1] {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer;
}
.margin_r_18[data-v-7de463a1] {
  margin-right: 18px;
}
.flex_center_[data-v-7de463a1] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.flex_center_center[data-v-7de463a1] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.panel-search[data-v-7de463a1] {
  margin: 20px;
  width: 100%;
}
.panel-search .logo[data-v-7de463a1] {
  width: 120px;
  margin-bottom: 20px;
}
.panel-search .logo img[data-v-7de463a1] {
  max-width: 100%;
}
.panel-search .panel-search__input-group[data-v-7de463a1] {
  height: 240px;
}
.panel-search .panel-search__input-group .panel-search__logo[data-v-7de463a1] {
  width: 80px;
  height: 80px;
  margin-bottom: 20px;
}
.panel-search .panel-search__input-group .panel-search__input[data-v-7de463a1] {
  width: 500px;
}
.panel-search .panel-search__input-group .panel-search__tip[data-v-7de463a1] {
  margin-top: 20px;
  margin-bottom: 40px;
  font-size: 12px;
  color: #909399;
}
.panel-search .panel-search__input-group .panel-search__tip .panel-search__key[data-v-7de463a1] {
  padding: 1px 5px;
  margin: 0px 2px;
  border-radius: 2px;
  background-color: #606266;
  color: #f8f8f9;
}
.panel-search .panel-search__results-group[data-v-7de463a1] {
  overflow: auto;
  margin-bottom: -20px;
}
.panel-search .panel-search__results-group .panel-search__results-group-inner[data-v-7de463a1] {
  margin: -20px;
}