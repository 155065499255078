.margin_r_18[data-v-72fc8d26] {
  margin-right: 18px;
}
.flex_center_[data-v-72fc8d26] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.flex_center_center[data-v-72fc8d26] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.upload_wrap[data-v-72fc8d26] {
  position: relative;
}
.upload_wrap.list-upload-wrap[data-v-72fc8d26] {
  width: auto !important;
  height: auto !important;
}
.upload_wrap.list-upload-wrap .list_upload > div[data-v-72fc8d26] {
  width: 100%;
  position: relative;
}
.upload_wrap.list-upload-wrap .list_upload > div[data-v-72fc8d26] .el-upload-list {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.upload_wrap.list-upload-wrap .list_upload > div[data-v-72fc8d26] .el-upload-list::before {
  display: block;
  content: "";
  width: 148px;
  height: 148px;
  margin-right: 10px;
}
.upload_wrap.list-upload-wrap .list_upload > div[data-v-72fc8d26] .el-upload--picture-card {
  position: absolute;
  top: 0;
  left: 0;
}
.upload_wrap.file-wrap[data-v-72fc8d26] {
  width: auto !important;
  height: 36px !important;
}
.upload_wrap.file-wrap .avatar-uploader[data-v-72fc8d26] .el-upload {
  border: 0;
  border-radius: 0;
}
.upload_wrap.file-wrap .avatar-uploader[data-v-72fc8d26] .el-upload .el-button {
  width: 120px;
  border-radius: 0 !important;
}
.upload_wrap.file-wrap > div[data-v-72fc8d26] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.upload_wrap.file-wrap > div a[data-v-72fc8d26] {
  color: #51cca3;
}
.upload_wrap.file-wrap > div .close-file[data-v-72fc8d26] {
  margin-left: 10px;
  cursor: pointer;
}
.upload_wrap.file-wrap > div .close-file[data-v-72fc8d26]:hover {
  color: #ff0000;
}
.upload_wrap.cus-class[data-v-72fc8d26] {
  width: 270px !important;
  height: 150px !important;
}
.upload_wrap.cus-class .avatar-uploader[data-v-72fc8d26] {
  overflow: hidden;
}
.upload_wrap.cus-class .avatar-uploader[data-v-72fc8d26] .el-upload {
  width: 100%;
  height: 100%;
  vertical-align: top;
  line-height: 1;
  padding: 0;
  border: 0;
  border-radius: 0;
  position: relative;
}
.upload_wrap.cus-class .avatar-uploader[data-v-72fc8d26] .el-upload .add-icon {
  display: block;
  width: 66px;
  height: 66px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -33px;
  margin-left: -33px;
}
.upload_wrap.cus-class .avatar-uploader[data-v-72fc8d26] .el-upload svg {
  margin-left: -15px;
}
.upload_wrap.cus-class .avatar-uploader[data-v-72fc8d26] .avatar {
  width: auto !important;
  max-width: 100%;
  height: 150px !important;
  display: block;
}
.upload_wrap .avatar-uploader[data-v-72fc8d26] .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.upload_wrap .avatar-uploader[data-v-72fc8d26] .el-upload:hover {
  border-color: #51cca3;
}
.upload_wrap .avatar-uploader[data-v-72fc8d26] .avatar-uploader-icon {
  font-size: 50px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
.upload_wrap .avatar-uploader[data-v-72fc8d26] .avatar {
  width: 100px;
  height: 100px;
  display: block;
}
.upload_wrap .close[data-v-72fc8d26] {
  position: absolute;
  top: -8px;
  right: -10px;
  background-color: #ff4400;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  color: #fff;
  font-size: 12px;
  cursor: pointer;
}